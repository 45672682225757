body {
    margin: 0;

    overscroll-behavior: contain;
    overflow: hidden;
}

body > iframe {
    display: none;
}

.fiber canvas {
    height: 100vh !important;
    width: 100vw !important;
}

.gyroEnabler {
    position: absolute;
    bottom: 4vw;
    right: 4vw;
    width: 10vw;
    border-radius: 1vw;
    opacity: 0.8;
    border: 0.5vw black solid;
}

.hover:hover, .c:hover {
    cursor: pointer;
    opacity: 0.8;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blink-image {
    animation: blink 1s infinite;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000ad;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.popup {
    cursor: pointer;
    color: yellow;
    font-size: 1.5rem;
    background: #000000;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

}


/*.opacity:hover{*/
/*    opacity: 0.6 !important;*/
/*}*/

@media (hover: hover) {
    .opacity:hover {
        opacity: 0.6 !important;
    }
}

.page2d {
    position: absolute;
    top: 0;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background: #000000ad;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.page2dInnerLand {
    height: 100vh;
    /*background-color: white;*/
    width: 33%;
}

.page2dInnerPort {
    height: 100vh;
    /*background-color: white;*/
    width: 100vw;
}

* {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none !important;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none !important;
}

.grey {
    color: grey;
    font-style: italic;
}

.row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.col {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.scrollbar {
    overflow-y: auto;
}

.scrollbar::-webkit-scrollbar-button {
    width: 0 !important;
    height: 0 !important;
}

.scrollbar::-webkit-scrollbar {
    width: 1vh;
}

.scrollbar::-webkit-scrollbar-track {
    width: 1vh;
    background-color: #E3E3E3;
}

.fadeOut {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn {
    opacity: 1;
    width: 100px;
    height: 100px;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #cacaca;
}

.d-f {
    display: flex;
    align-items: center;
}

.d-fcol {
    display: flex;
    flex-direction: column;
}

.gap1 {
    gap: 4px;
}

.gap2 {
    gap: 8px;
}

.gap3 {
    gap: 12px;
}

.gap4 {
    gap: 16px;
}

.m1 {
    margin: 4px;
}

.m2 {
    margin: 8px;
}

.m3 {
    margin: 12px;
}

.m4 {
    margin: 16px;
}

.u {
    text-decoration: underline;
}

.center {
    text-align: center;
    align-self: center;
}

.sold {
    color: #ff8100;
    font-style: italic;
    padding-left: 4px;
}

.alignCenter {
    align-items: center;
}

.font1 {
    font-size: 16px;
}

.font2 {
    font-size: 18px;
}

.font3 {
    font-size: 20px;
}

.font4 {
    font-size: 22px;
}

@font-face {
    font-family: 'texas tango';
    src: url('scene3Font.otf') format('truetype');
}

@font-face {
    font-family: 'Digital-7';
    src: url('digital-7.ttf') format('truetype');
}

.clickableEmailList:hover {
    cursor: pointer;
    background-color: #383838;
}

.fade {
    -webkit-transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.redScroll::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar thumb */
.redScroll::-webkit-scrollbar-thumb {
    background-color: #c6cef6; /* Set the color of the thumb */
    border-radius: 5px; /* Optional: Rounded corners for the thumb */
}

/* Customize the scrollbar track */
.redScroll::-webkit-scrollbar-track {
    background-color: #dedeff; /* Set the color of the track */
}

.simple-scroll::-webkit-scrollbar {
    width: 5px;
}

.simple-scroll::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.43);
}

.simple-scroll::-webkit-scrollbar-thumb {
    background: rgba(126, 126, 126, 0.43);
    border-radius: 5px;
}

u {
    text-decoration: underline;
    display: inline;
}

p {
    margin: 0;
                        lineHeight: "30px",
}