a:link {
    color: #0a53be !important;
    /*text-decoration: underline !important;*/
}

a:visited {
    color: #000000 !important;
}

a{
    text-decoration: none;
}
